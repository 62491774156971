import * as AT from "../action.types"

const INITIAL_STATE = {
  data: "",
  status: null,
  active: null,
}
const requestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AT.PERFORM_REQUEST.ERROR:
    case AT.PERFORM_REQUEST.SUCCESS:
      return {
        ...state,
        data: JSON.stringify(action.payload.data, null, 2),
        status: action.payload.status,
      }

    case AT.CLEAR_REQUEST:
      return INITIAL_STATE

    case AT.SET_ACTIVE_REQUEST:
      return {
        ...state,
        active: action.payload.active
      };

    default:
      return state
  }
}

export default requestReducer
