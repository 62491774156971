import * as libColors from "components/common/theme/colors"
import * as typography from "components/common/theme/typography"
import * as animation from "components/common/theme/animations"

// DEFAULT COMPONENTS HEIGHTS
const SMALL = "24px"
const MEDIUM = "30px"
const LARGE = "34px"
const size = {
  SMALL,
  MEDIUM,
  LARGE,
}

const lightTheme = {
  ...libColors.lightTheme,
  text: typography,
  animation,
  size,
}

const darkTheme = {
  ...libColors.darkTheme,
  text: typography,
  animation,
  size,
}

export const getTheme = dark => dark ? darkTheme : lightTheme
