import * as AT from "../action.types"

const INITIAL_STATE = {
  data: "",
  error: false
}
const measurementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AT.GET_MEASUREMENTS.ERROR:
    case AT.GET_MEASUREMENTS.SUCCESS:
      return {
        ...state,
        data: JSON.stringify(action.payload.data, null, 2),
        status: action.payload.status,
      }

    default:
      return state
  }
}

export default measurementsReducer
