export const getUrlParams = url => {
  const params = {}
  const parser = document.createElement("a")
  
  parser.href = url
  
  const query = parser.search.substring(1)
  const vars = query.split("&")
  
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    
    if (pair[0]) {
      params[pair[0]] = decodeURIComponent(pair[1])
    }
  }
  
  return params
}

export const getParams = url => {
  const params = {}
  const parts = url.split("?")
  const split = parts[0].split("/")
  
  for (let i = 0; i < split.length; i++) {
    if (split[i].startsWith(":")) {
      params[split[i].substr(1)] = "untitled"
    }
  }
  
  return params
}
