import * as AT from "../action.types"

const INITIAL_STATE = {
  fetched: false,
  lastCheck: new Date(),
  darkMode: false,
  alive: false,
}

// Flatten the respond from me-extended to be consist with the old v1/users/me respond,
//  me-extended respond
//  {
//    apiUserDto: { user info like in users/me },
//    featureFlags: { map of all the feature flags}
// }
// To be:
// {
//  ... same as old data
//  featureFlags: { .. }  // new  property
//  }
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AT.FETCH_USER.SUCCESS:
      return {
        ...state,
        ...action.payload.data.apiUserDto,
        featureFlags: action.payload.data.featureFlags,
        fetched: true,
      }
    
    case AT.IS_ALIVE.SUCCESS:
      return {
        ...state,
        alive: action.payload.data,
        lastCheck: new Date(),
      }
    
    case AT.IS_ALIVE.ERROR:
      return {
        ...state,
        alive: false,
      }
    
    case AT.TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      }
    
    default:
      return state
  }
}

export default userReducer
