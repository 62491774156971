import { isPending, isSuccess, isError, getTypeBase } from "../action.types"

/**
 * Holds the response from the sdk.onLoad function
 * user, account and workspace data.
 **/
const INITIAL_STATE = {}
const networkReducer = (state = INITIAL_STATE, action) => {
  if (isPending(action.type)) {
    return {
      ...state,
      [getTypeBase(action.type)]: true,
    }
  }
  
  if (isError(action.type) || isSuccess(action.type)) {
    return {
      ...state,
      [getTypeBase(action.type)]: false,
    }
  }
  
  return state
}

export default networkReducer
