import * as AT from "../action.types"

export const performRequest = meta => ({
  type: AT.API_REQUEST,
  meta: {
    ...meta,
    baseAction: AT.PERFORM_REQUEST,
  },
})

// me-extended respond
//  {
//    apiUserDto: { user info like in users/me },
//    featureFlags: { map of all the feature flags}
// }
export const fetchUser = ({ serverUrl, token }) => ({
  type: AT.API_REQUEST,
  meta: {
    method: "GET",
    url: `${serverUrl}/v1/users/me-extended`,
    headers: {
      token,
    },
    baseAction: AT.FETCH_USER,
  },
})

export const getDataStream = meta => ({
  type: AT.API_REQUEST,
  meta: {
    ...meta,
    baseAction: AT.GET_DATA_STREAM,
  },
})

export const getMeasurements = meta => ({
  type: AT.API_REQUEST,
  meta: {
    ...meta,
    baseAction: AT.GET_MEASUREMENTS,
  },
})

export const isAlive = ({ serverUrl, token }) => ({
  type: AT.API_REQUEST,
  meta: {
    method: "POST",
    url: `${serverUrl}/services/v2/auth/issessionalive`,
    headers: {
      token,
    },
    baseAction: AT.IS_ALIVE,
  },
})

export const clearRequest = () => ({
  type: AT.CLEAR_REQUEST,
})

export const setActiveRequest = active => ({
  type: AT.SET_ACTIVE_REQUEST,
  payload: {
    active,
  },
})
