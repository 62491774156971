import {combineReducers} from "redux"
// common reducers
import network from "redux/reducers/network.reducer"
import session from "redux/reducers/session.reducer"
import request from "redux/reducers/request.reducer"
import user from "redux/reducers/user.reducer"
import datastream from "redux/reducers/datastream.reducer"
import measurements from "redux/reducers/measurements.reducer"

export default combineReducers({
  network,
  session,
  request,
  user,
  datastream,
  measurements
})
