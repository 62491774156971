import * as AT from "redux/action.types"

/**
 * Holds the response from the sdk.onLoad function
 * user, account and workspace data.
 **/
const INITIAL_STATE = {
  token: null,
  env: null,
}

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AT.SET_PARAMS:
      const { params } = action.payload
      
      if (state.token === params.token && state.env === params.env) {
        return state
      }
      
      return params
    
    default:
      return state
  }
}

export default sessionReducer
