import { store } from "redux/store";

const envMap = {
  app: {
    origin: "platform",
    server: "app",
    api: "api",
  },
  "app-eu": {
    origin: "platform-eu",
    server: "app-eu",
    api: "api-eu",
  },
  "app-us2": {
    origin: "platform-us2",
    server: "app-us2",
    api: "api-us2",
  },
  "app-eu2": {
    origin: "platform-eu2",
    server: "app-eu2",
    api: "api-eu2",
  },
  ci: {
    origin: "ci-platform",
    server: "ci",
    api: "api-ci",
  },
  stg: {
    origin: "stg-platform",
    server: "stg",
    api: "api-stg",
  },
  dev: {
    origin: "dev",
    server: "dev",
    api: "dev",
  },
}

const getEnv = () => {
  const env = store.getState().session.env
  const environment = envMap[env || "app"]
  const isDev = environment && environment.server === envMap.dev.server

  // Feature environment support
  //  should be: fe-prov-8-web.dev
  if (env && env.startsWith('fe')) {
    return {
      origin: env.replace('-web', '-platform') + '.dev',
      server: env + '.dev',
      api: env + '.dev',
      scheme: isDev ? "http" : "https",
      serverPort: isDev ? ":8081" : "",
      clientPort: isDev ? ":3000" : "",
      domainSuffix: 'io',
    }
  }

  return {
    env,
    ...environment,
    scheme: isDev ? "http" : "https",
    serverPort: isDev ? ":8081" : "",
    clientPort: isDev ? ":3000" : "",
    domainSuffix: 'com',
  }
}

export const getServerUrl = () => {
  const envParams = getEnv()
  return `${envParams.scheme}://${envParams.server}.datorama.${envParams.domainSuffix}${envParams.serverPort}`
}

export const getApiHost = () => {
  const envParams = getEnv()
  return `${envParams.scheme}://${envParams.api}.datorama.${envParams.domainSuffix}${envParams.serverPort}`
}

export const getRedirectUrl = () => {
  const envParams = getEnv()
  return `${envParams.scheme}://${envParams.origin}.datorama.${envParams.domainSuffix}${envParams.serverPort}/developer-portal`
}
