import * as AT from "../action.types"

const INITIAL_STATE = {
  data: "",
  error: false
}
const datastreamReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AT.GET_DATA_STREAM.PENDING:
      return {
        ...state,
        error: false
      };

    case AT.GET_DATA_STREAM.ERROR:
      return {
        ...state,
        error: true
      };
    case AT.GET_DATA_STREAM.SUCCESS:
      return {
        ...state,
        data: JSON.stringify(action.payload.data, null, 2),
        status: action.payload.status,
        error: false
      }

    default:
      return state
  }
}

export default datastreamReducer
