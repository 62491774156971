import { createStore, compose, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import rootReducer from "redux/reducers/root.reducer"
// middlewares
import apiMiddleware from "redux/middlewares/api.middleware"
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};
const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
  key: "DATO_DEV_PORTAL",
  storage,
  whitelist: ["session"],
}

const middlewares = [apiMiddleware]
const persistedReducer = persistReducer(persistConfig, rootReducer)

const storeEnhancers = compose(applyMiddleware(...middlewares))

export const store = createStore(persistedReducer, {}, storeEnhancers)
export const persistor = persistStore(store)
