import React, { Fragment, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { Provider } from "react-redux"
import { store, persistor } from "redux/store"
import { isEmpty } from "lodash/fp"
import { useDispatch, useSelector } from "react-redux"
import { getUrlParams } from "utils/url.utils"
import { setParams } from "redux/actions/session.actions"
import { fetchUser, isAlive } from "redux/actions/requests.actions"
import { getTheme } from "components/common/theme"
import { getServerUrl } from "utils/env.utils"
// import DatoSpinner from "components/common/DatoSpinner"
import { PersistGate } from "redux-persist/integration/react"

const Layout = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Connected>
        <Fragment>
          {children}
        </Fragment>
      </Connected>
    </PersistGate>
  </Provider>
)

const Connected = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const darkMode = useSelector(state => state.user.darkMode)

  let params = {}
  if (typeof window !== `undefined`) {
    params = getUrlParams(window.location.search)

    //
    // Allow local override of remote environment and token via .env file
    //
    if (!isEmpty(process.env.ENV) && !isEmpty(process.env.TOKEN)) {
      params = {
        env: process.env.ENV,
        token: process.env.TOKEN
      }
    }

    if (!isEmpty(params)) {
      window.history.replaceState(null, null, window.location.pathname)
      dispatch(setParams(params))
    }
  }

  let session = useSelector(state => state.session)

  if (!isEmpty(params)) {
    session = params
  }

  // check time diff
  // const MAX = 10
  // const diff = Math.abs(Math.round(((user.lastCheck.getTime() - new Date().getTime()) / 1000) / 60))
  // const refresh = diff >= MAX

  const serverUrl = getServerUrl();


  // check once
  useEffect(() => {
    dispatch(isAlive({
      serverUrl,
      token: session.token,
    }))
  }, [session.token, dispatch, serverUrl])

  useEffect(() => {
    if (user.alive && !user.fetched) {
      dispatch(fetchUser({
        serverUrl,
        token: session.token,
      }))
    }
  }, [session, user, dispatch, serverUrl])

  // if (!user.alive || !user.fetched) {
  //   return <DatoSpinner/>
  // }

  return (
    <ThemeProvider theme={getTheme(darkMode)}>
      {children}
    </ThemeProvider>
  )
}

export default Layout
