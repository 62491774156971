import { endsWith } from "lodash/fp"

export const apiRequest = base => ({
  BASE: base,
  SUCCESS: `${base}_SUCCESS`,
  ERROR: `${base}_ERROR`,
  PENDING: `${base}_PENDING`,
})

export const getTypeBase = type =>
  type.replace(/_SUCCESS|_ERROR|_PENDING/g, "")
export const isSuccess = endsWith("_SUCCESS")
export const isError = endsWith("_ERROR")
export const isPending = endsWith("_PENDING")

// REQUESTS METHODS
export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const DELETE = "DELETE"

export const API_REQUEST = "API_REQUEST"
export const SET_PARAMS = "SET_PARAMS"

export const PERFORM_REQUEST = apiRequest("PERFORM_REQUEST")
export const GET_DATA_STREAM = apiRequest("GET_DATA_STREAM")
export const GET_MEASUREMENTS= apiRequest("GET_MEASUREMENTS")

  export const FETCH_USER = apiRequest("FETCH_USER")
export const IS_ALIVE = apiRequest("IS_ALIVE")

export const CLEAR_REQUEST = "CLEAR_REQUEST"
export const SET_ACTIVE_REQUEST = "SET_ACTIVE_REQUEST"

export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE"
