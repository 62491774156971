import * as AT from "../action.types"
import {getRedirectUrl} from "utils/env.utils"

class CustomError extends Error {
  constructor(status = null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }

    this.name = "CustomError"

    // Custom debugging information
    this.status = status
  }
}

const api = ({dispatch, getState}) => next => action => {
  if (action.type !== AT.API_REQUEST) {
    return next(action)
  }

  const {meta} = action
  const options = {
    method: meta.method,
    credentials: 'include'
  }

  if (meta.headers) {
    options.headers = meta.headers
  }

  if (meta.body) {
    options.body = meta.body
  }

  dispatch({type: meta.baseAction.PENDING})

  const handleResponse = async response => {
    if (!response.ok) {
      throw new CustomError(response.status, response.statusText)
    }

    const data = await response.json()

    return {data, status: response.status}
  }

  fetch(meta.url, options).then(handleResponse)
      .then(payload => {
        if (meta.baseAction.BASE === "IS_ALIVE" && !payload.data) {

          window.location = getRedirectUrl()
        }

        return dispatch({type: meta.baseAction.SUCCESS, payload})
      })
      .catch(res => {
        if (res.status === 401 && !['GET_DATA_STREAM', 'PERFORM_REQUEST'].some(type => type === meta.bastAction.BASE)) {
          window.location = getRedirectUrl()
        }
        return dispatch({type: meta.baseAction.ERROR, payload: {data: res.message, status: res.status}})
      })
}

export default api
